<script lang="ts">
    import { _ } from "svelte-i18n";
    import moment from "moment";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import { COUNTRIES } from "@/static/constant";
    import environment from "@/services/environment";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import SelectDate from "../../../components/selectDate/SelectDate.svelte";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import type { ICountry, IDashboardData, IDashboardOrderStateData } from "../../../static/types";
    import {
        account,
        showAccountAlert,
        signupComplete
    } from "../../../store/merchant/account.store";
    import dashboardState, { dashboard } from "../../../store/merchant/dashboard.store";
    import utils, { type CurrencyFormatOptions } from "../../../utilsV2/currency";
    import Chart from "./Chart.svelte";
    import { DASHBOARD_EXCEL_DOWNLOAD } from "../../../static/endPoints";
    import { getBaseUrl } from "../../../utils/functions";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import * as helpLinks from "../../../services/helpCentreService";

    const helpMenuLinks = {
        [$_("helpLinks.portalTour")]: helpLinks.PORTAL_TOUR,
        [$_("helpLinks.merchantFaq")]: helpLinks.MERCHANT_FAQ,
        [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };

    let dashboardData: IDashboardData = {};
    let merchantMarketCountry: ICountry;
    let startDate = new Date(new Date().getTime() - 30 * 24 * 3600000);
    let endDate = new Date();
    let avgOrderValue: number = 0;
    let creditApproval: number = 0;
    let totalSales: number = 0;
    let fulfilmentRate: number = 0;
    let count: number = 0;
    let uniqueBuyers: number = 0;
    let verificationRate: number = 0;
    let totalRefunds: number = 0;

    let incAvgOrderValue: number | "∞" = 0;
    let inctotalSales: number | "∞" = 0;
    let incOrderPlaced: number | "∞" = 0;

    //---------------------------------------
    // Functions
    //---------------------------------------
    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(merchantMarketCountry?.currency)(money, options);

    function onChangeDateRange(d) {
        dashboardData = {};
        startDate = d.detail.startDate;
        endDate = d.detail.endDate;
    }

    function getDashboard(startDate, endDate, market: ICountry) {
        if (market != null) {
            dashboardState.actions.getDashboard(
                startDate,
                endDate,
                market.country_code,
                merchantMarketCountry?.currency,
                $signupComplete
            );
        }
    }

    //---------------------------------------
    // Reactive blocks
    //---------------------------------------

    // Subscribe to merchant account data
    $: if ($account.countryCode) {
        merchantMarketCountry = Object.values(COUNTRIES).find(
            (el: ICountry) => el.country_code == $account.countryCode
        );
        getDashboard(startDate, endDate, merchantMarketCountry);
    }

    const getPercentagePrefix = (value) => (value > 0 ? "+" : "");

    // Subscribe to dashboard data
    $: if ($dashboard?.credit_approval_rate) {
        dashboardData = $dashboard;
        creditApproval = roundToInt($dashboard.credit_approval_rate);
        useFulfillmentData(dashboardData.fulfilled_order_data, dashboardData.refunded_order_data);
    }

    const roundToInt = (value) => Math.round(Number(value || "0"));

    function downloadDashboardOrders() {
        const query =
            "?" +
            new URLSearchParams({
                end_date: endDate.toDateString(),
                start_date: startDate.toDateString()
            }).toString();

        window.location.href = `${getBaseUrl()}${DASHBOARD_EXCEL_DOWNLOAD}${query}`;
    }

    const handlePercentage = (value: string | number) => {
        if (value !== "∞") {
            value = Number(value || "0") * 100;
        }
        return value;
    };
    function useFulfillmentData(
        data: IDashboardOrderStateData,
        refundData: IDashboardOrderStateData
    ) {
        avgOrderValue = Number(data?.average_order_value || "0");
        totalSales = Number(data?.total_value || "0");
        totalRefunds = Number(refundData?.total_value || "0");
        fulfilmentRate = roundToInt(dashboardData.capture_rate);
        count = Number(data?.order_count || "0");
        uniqueBuyers = dashboardData?.unique_buyers || 0;
        verificationRate = roundToInt(dashboardData?.verification_rate);
        incAvgOrderValue = handlePercentage(data?.incr_average_order_value);
        inctotalSales = handlePercentage(data?.incr_total_value);
        incOrderPlaced = handlePercentage(data?.incr_order_count);
    }
</script>

<!--  divitis to get rid of here -->
<MetaWrapper title={$_("dashboard.pageHeader.title")}>
    <div class="page-container">
        {#if $signupComplete}
            <PageHeader
                title={$_("dashboard.pageHeader.title")}
                showAccountAlert={$showAccountAlert}
                newUser={!$account?.flags?.has_made_order}
                {helpMenuLinks}
            >
                <span
                    slot="cta"
                    class={"cursor-pointer link-text hover:text-main"}
                    on:click={downloadDashboardOrders}
                    on:keypress={downloadDashboardOrders}
                >
                    <p>{$_("dashboard.pageHeader.downloadCta")}</p>
                </span>
            </PageHeader>
        {:else}
            <PageHeader
                title={$_("dashboard.pageHeader.title")}
                showAccountAlert={$showAccountAlert}
                newUser={!$account?.flags?.has_made_order}
            />
        {/if}
        <div class="content-container">
            <div class="flex items-center justify-between mx-8 mt-8">
                <div class="max-w-7xl w-60">
                    <SelectDate
                        label={$_("dashboard.dateRange.label")}
                        on:changeDate={onChangeDateRange}
                        {startDate}
                        {endDate}
                    />
                </div>
            </div>
            <div class="text-slight-dark main-content">
                <div class="card">
                    <div class="flex items-center">
                        <p class="ctn-general-tertiary font-normal">
                            {$_("dashboard.fulfilledOrders.title")}
                        </p>
                        <span class="ml-2 z-10 hide-tooltip">
                            <ToolTip
                                width="250px"
                                position="right"
                                placement="start"
                                content={$_("dashboard.fulfilledOrders.tooltip")}
                            >
                                <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                            </ToolTip>
                        </span>
                    </div>
                    <div class="flex items-center">
                        <p class="text-3xl main-values">
                            {formatMoney(totalSales - Math.abs(totalRefunds))}
                        </p>
                    </div>
                    <p>
                        <span
                            class:increased={inctotalSales === "∞" || inctotalSales >= 0}
                            class:decreased={typeof inctotalSales === "number" && inctotalSales < 0}
                        >
                            {#if typeof inctotalSales === "string"}
                                ∞%
                            {:else}
                                {getPercentagePrefix(inctotalSales)}{Math.round(
                                    inctotalSales
                                ).toLocaleString()}%
                            {/if}
                            <span class="ctn-general-tertiary font-normal">
                                {$_("dashboard.fulfilledOrders.percentageChange", {
                                    values: {
                                        days: moment(endDate).diff(moment(startDate), "days") + 1
                                    }
                                })}</span
                            >
                        </span>
                    </p>
                </div>
                <div class="card">
                    <div class="flex items-center">
                        <p class="ctn-general-tertiary font-normal">
                            {$_("dashboard.avgOrderValue.title")}
                        </p>
                        <span class="ml-2 z-10 hide-tooltip">
                            <ToolTip
                                width="250px"
                                position="right"
                                placement="start"
                                content={$_("dashboard.avgOrderValue.tooltip")}
                            >
                                <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                            </ToolTip>
                        </span>
                    </div>
                    <div class="flex items-center">
                        <p class="text-3xl main-values">
                            {formatMoney(avgOrderValue)}
                        </p>
                    </div>
                    <p>
                        <span
                            class:increased={incAvgOrderValue === "∞" || incAvgOrderValue >= 0}
                            class:decreased={typeof incAvgOrderValue === "number" &&
                                incAvgOrderValue < 0}
                        >
                            {#if typeof incAvgOrderValue === "string"}
                                ∞%
                            {:else}
                                {getPercentagePrefix(incAvgOrderValue)}{Math.round(
                                    incAvgOrderValue
                                ).toLocaleString()}%
                            {/if}
                        </span>
                        <span class="ctn-general-tertiary font-normal">
                            {$_("dashboard.avgOrderValue.percentageChange", {
                                values: {
                                    days: moment(endDate).diff(moment(startDate), "days") + 1
                                }
                            })}
                        </span>
                    </p>
                </div>
                <div class="card">
                    <div class="flex items-center">
                        <p class="ctn-general-tertiary font-normal">
                            {$_("dashboard.fulfilments.title")}
                        </p>
                        <span class="ml-2 z-10 hide-tooltip">
                            <ToolTip
                                width="250px"
                                position="right"
                                placement="start"
                                content={$_("dashboard.fulfilments.tooltip")}
                            >
                                <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                            </ToolTip>
                        </span>
                    </div>
                    <div class="flex items-center">
                        <p class="text-3xl main-values">{count}</p>
                    </div>
                    <p>
                        <span
                            class:increased={incOrderPlaced === "∞" || incOrderPlaced >= 0}
                            class:decreased={typeof incOrderPlaced === "number" &&
                                incOrderPlaced < 0}
                        >
                            {getPercentagePrefix(incOrderPlaced)}{incOrderPlaced === "∞"
                                ? incOrderPlaced
                                : Math.round(incOrderPlaced).toLocaleString()}%

                            <span class="ctn-general-tertiary font-normal">
                                {$_("dashboard.fulfilments.percentageChange", {
                                    values: {
                                        days: moment(endDate).diff(moment(startDate), "days") + 1
                                    }
                                })}</span
                            >
                        </span>
                    </p>
                </div>
                <div class="chart_container">
                    <Chart
                        data={dashboardData.fulfilled_order_data}
                        refundData={dashboardData.refunded_order_data}
                        currency={merchantMarketCountry?.currency}
                    />
                </div>
                <div class="card-long">
                    <div id="main-container" class="flex flex-row justify-between h-full">
                        <div class="card-half">
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.uniqueCustomers.title")}
                                    </p>
                                    <span class="ml-2 z-10 hide-tooltip">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.uniqueCustomers.tooltip", {
                                                values: {
                                                    branding: environment.branding.displayName
                                                }
                                            })}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>

                                <p class="text-3xl mt-3 main-values">{uniqueBuyers}</p>
                            </div>

                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.fulfilmentRate.title")}
                                    </p>
                                    <span class="ml-2 z-10 hide-tooltip">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.fulfilmentRate.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>
                                <p class="text-3xl mt-3 w-full main-values">
                                    {fulfilmentRate}%
                                </p>
                            </div>
                        </div>
                        <div class="card-half custom-border-l">
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.conversionRate.title")}
                                    </p>
                                    <span class="ml-2 z-20 hide-tooltip">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.conversionRate.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>

                                <p class="text-3xl mt-3 w-full main-values">
                                    {verificationRate}%
                                </p>
                            </div>
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.creditApproval.title")}
                                    </p>
                                    <span class="ml-2 z-10 hide-tooltip">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.creditApproval.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>
                                <p class="text-3xl mt-3 w-full main-values">
                                    {creditApproval}%
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</MetaWrapper>

<style>
    .link-text {
        color: var(--ctnInteractiveSemanticHighlightDefault);
        font-weight: 500;
    }

    .card {
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        height: auto;
        border: 1px solid var(--gray-100);
        grid-column: span 2;
        display: grid;
        grid-gap: 0.5rem;
        align-items: center;
        align-content: space-between;
    }

    .card-long {
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        min-height: 50%;
        border-top: 0;
        border-left: 1px solid var(--gray-100);
        border-right: 1px solid var(--gray-100);
        border-bottom: 1px solid var(--gray-100);
        grid-column: span 2;
        height: fit-content;
    }

    .main-values {
        color: var(--ctnGeneralPrimary);
    }

    .increased {
        color: #20b877;
    }

    .decreased {
        color: var(--error-700);
    }

    .chart_container {
        min-height: 400px;
        grid-column: 1 / span 4;
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        height: auto;
        border: 1px solid var(--gray-100);
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
    }

    /* normal border-l does not seem to be working */
    .custom-border-l {
        border-left-width: 1px;
    }

    .card-quadrant-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0.5rem;
        align-items: center;
    }
    .card-quadrant {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }
    #main-container .card-half:nth-child(2) {
        padding-left: 20px;
    }
    .main-content {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1.5rem;
    }

    .hide-tooltip {
        visibility: hidden;
    }

    :is(.card, .card-quadrant):hover .hide-tooltip {
        visibility: visible;
    }
</style>
