<script lang="ts">
    import { Tooltip, Button as TwoUIButton } from "@two-ui/core";
    import { _ } from "svelte-i18n";

    import environment from "@/services/environment";
    import ExternalLinkIcon from "../icons/external-link.svelte";
    import { clickOutside } from "../../utilsV2/clickOutside";
    import QuestionIcon from "../../components/icons/question_comment.svelte";
    import { onMount } from "svelte";
    import { DOCS_URL } from "../../static/endPoints";
    import * as helpLinks from "../../services/helpCentreService";
    import { account } from "../../store/merchant/account.store";

    export let helpMenuLinks: Record<string, string> = {};

    const merchantHelpLinks = {
        [$_("helpLinks.buyerFaq")]: helpLinks.MERCHANT_FAQ,
        [$_("helpLinks.docsUrl")]: DOCS_URL,
        [$_("helpLinks.twoStatus")]: helpLinks.TWO_STATUS
    };

    const merchantDefaultHelpMenuLinks = {
        [$_("helpLinks.portalTour")]: helpLinks.PORTAL_TOUR,
        [$_("helpLinks.merchantFaq")]: helpLinks.MERCHANT_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };

    const buyerHelpLinks = {
        [$_("helpLinks.buyerFaq")]: helpLinks.BUYER_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };

    const buyerDefaultHelpMenuLinks = {
        [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
    };

    let showSupportDropdown = false;
    let hasSeenPageHelp = false;
    let isBuyerPortal = true;
    let staticHelpLinks: Record<string, string> = {};
    let _helpMenuLinks: Record<string, string>;

    onMount(loadMenu);

    $: newUser = !$account?.flags?.has_made_order;
    $: newUserTooltipActive = newUser && !showSupportDropdown && !hasSeenPageHelp && !isBuyerPortal;

    function loadMenu() {
        // TODO replace with less hacky way of determining merchant vs buyer portal
        isBuyerPortal = window.location.pathname.split("/").at(1) === "buyer";
        if (isBuyerPortal) {
            staticHelpLinks = buyerHelpLinks;
            _helpMenuLinks = getHelpMenuLinks(helpMenuLinks, buyerDefaultHelpMenuLinks);
        } else {
            staticHelpLinks = merchantHelpLinks;
            _helpMenuLinks = getHelpMenuLinks(helpMenuLinks, merchantDefaultHelpMenuLinks);
        }
        hasSeenPageHelp = Boolean(localStorage.getItem("acknowledged_help_button"));
    }

    function getHelpMenuLinks(
        helpMenuLinks: Record<string, string>,
        defaultHelpMenuLinks: Record<string, string>
    ): Record<string, string> {
        if (Object.keys(helpMenuLinks).length) {
            return helpMenuLinks;
        } else {
            return defaultHelpMenuLinks;
        }
    }

    function acknowledgedHelpButton() {
        localStorage.setItem("acknowledged_help_button", "true");
        hasSeenPageHelp = true;
    }

    // TODO make util
    function openPage(link: string) {
        window.open(link);
    }

    function openHelpChat() {
        const iframe = document.getElementById("launcher") as HTMLIFrameElement;
        iframe.contentWindow.document.getElementsByTagName("button")[0].click();
        showSupportDropdown = false;
    }

    function toggleHelpMenu(event: MouseEvent) {
        event.stopPropagation();
        showSupportDropdown = !showSupportDropdown;
        acknowledgedHelpButton();
    }
    function handleClickOutsideHelpMenu(event: MouseEvent) {
        event.stopPropagation();
        if (showSupportDropdown) {
            showSupportDropdown = false;
        }
    }
</script>

<Tooltip
    width="100%"
    placement="center"
    wrapLines
    label={$_("pageHeader.helpText")}
    position="bottom"
>
    <TwoUIButton
        id="help-menu-btn"
        on:click={toggleHelpMenu}
        size="md"
        variant="secondaryGray"
        content={""}
        ><QuestionIcon size={20} slot="rightIcon" />
    </TwoUIButton>
</Tooltip>
{#if newUserTooltipActive}
    <div id="help-menu-tooltip">
        <h1 style="font-weight:700;font-size:16px;">
            {$_("pageHeader.helpTooltipTitle")} 🛟
        </h1>
        <p class="small-text">{$_("pageHeader.helpTooltipDescription")}</p>
        <div style="display:flex;justify-content: flex-end;">
            <button id="new-user-tooltip-btn" on:click={acknowledgedHelpButton}>
                {$_("pageHeader.helpTooltipButton")}
            </button>
        </div>
    </div>
{/if}
{#if showSupportDropdown}
    <div
        id="help-menu"
        use:clickOutside={{ enabled: true }}
        on:click_outside={handleClickOutsideHelpMenu}
    >
        <div class="drop-down-links">
            {#each Object.entries(staticHelpLinks) as [label, link]}
                <button on:click={() => openPage(link)}>
                    <p>{label}</p>
                    <ExternalLinkIcon fill="var(--primary-500)" />
                </button>
            {/each}
        </div>
        <div class="pill-button-links">
            {#each Object.entries(_helpMenuLinks) as [label, link]}
                <button on:click={() => openPage(link)}><p>{label}</p></button>
            {/each}
        </div>
        {#if environment.branding.hasSupportChat}
            <div class="help-form small-text">
                <span class="help-text">
                    {$_("pageHeader.needSpecificHelp")}
                    <button on:click={openHelpChat}><p>{$_("pageHeader.theForm")}</p></button>
                    {$_("pageHeader.below")}
                </span>
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    :global(#help-menu-btn) {
        border: 1px solid var(--ctnInteractiveGeneralDefault);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
    }

    :global(#docs-btn) {
        border: 1px solid var(--gray-300);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    :global(#help-menu-btn.active) {
        box-shadow:
            0px 0px 0px 4px rgba(242, 244, 247, 1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    :global(#help-menu-btn:hover) {
        border: 1px solid var(--gray-400);
    }
    #help-menu-tooltip {
        z-index: 20;
        position: absolute;
        right: 1rem;
        top: 95px;
        width: 284px;
        border-radius: 8px;
        background-color: var(--gray-700);
        color: var(--gray-200);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    #help-menu-tooltip::before {
        /* adds point to tooltip */
        position: absolute;
        content: " ";
        bottom: 100%;
        left: 83.5%;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent var(--gray-700) transparent;
        margin-top: -2px; /* margin needs tooltip::before padding - margin, as negative value */
    }

    #help-menu {
        border: 1px solid var(--gray-200);
        border-radius: 8px;
        box-shadow: 0px 4px 16px 6px #4e5ba614;
        z-index: 300;
        position: absolute;
        right: 2rem;
        top: 90px;
        padding-bottom: 1rem;
        background-color: white;
        width: 295px;
        letter-spacing: 0;
    }
    #help-menu p {
        color: var(--primary-500);
    }
    .drop-down-links button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding-bottom: 10px;
        padding: 6px 16px 6px 16px;
    }
    .drop-down-links button:active {
        background-color: var(--primary-50);
    }
    .drop-down-links button:hover {
        background-color: var(--primary-25);
    }
    .drop-down-links {
        display: flex;
        flex-direction: column;
    }
    .drop-down-links > button:first-child {
        padding-top: 12px;
    }
    .drop-down-links :last-child {
        padding-bottom: 12px;
    }
    .pill-button-links {
        border-top: 1px solid var(--gray-200);
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        flex-wrap: wrap;
        padding: 10px 16px 10px 16px;
    }
    .pill-button-links button {
        padding: 2px 10px 2px 10px;
        border-radius: 16px;
        background-color: var(--primary-50);
        color: var(--primary-500);
    }
    .drop-down-links button:active p {
        color: var(--primary-600) !important;
    }
    .pill-button-links button:hover,
    .pill-button-links button:hover p {
        background-color: var(--bgSemanticHighlightPrimary);
        color: var(--ctnInteractiveGeneralInverse) !important;
    }
    .pill-button-links button:active,
    .pill-button-links button:active p {
        color: var(--primary-600) !important;
        background-color: var(--primary-100);
    }
    .pill-button-links button:active,
    .pill-button-links button:active p {
        background-color: var(--primary-100);
        color: var(--primary-600) !important;
    }
    .help-form {
        border-top: 1px solid var(--gray-200);
        padding: 10px 16px 0px 16px;
    }
    .help-form span {
        color: var(--ctnGeneralSecondary);
    }
    #help-menu-tooltip button {
        background-color: var(--gray-200);
        color: var(--gray-700);
        box-shadow: 0px 1px 2px 0px #1018280d;
        border-radius: 8px;
        padding: 8px 14px 8px 14px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    .small-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
    }

    #help-menu-tooltip button:hover {
        background-color: var(--basic);
    }
</style>
