<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button } from "@two-ui/core";
    import { navigate } from "svelte-routing";

    import Card from "../../../../components/card/Card.svelte";
    import RiskChart from "../common/RiskChart.svelte";
    import {
        getLosesPerMonthChartData,
        getLostPercentageVolumeChartData,
        getFraudLossesPerMonthChartData,
        getFraudLostPercentageVolumeChartData,
        getExposureChangeOverTimeChartData
    } from "../common/charts";
    import environment from "@/services/environment";
</script>

<section class="top-cards">
    <Card
        id="total-volume"
        text={$_("riskManagement.overview.totalVolume")}
        value="kr 1,360,398"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        <span class="change-value">{"+20% "}</span>{$_("riskManagement.overview.vsPrevious30Days")}
    </Card>
    <Card
        id="fraud-prevented"
        text={$_("riskManagement.overview.fraudPrevented")}
        value="kr 93,201"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        <span class="change-value">{"+20% "}</span>{$_("riskManagement.overview.vsPrevious30Days")}
    </Card>
    <Card
        id="authenticated-customers"
        text={$_("riskManagement.overview.authenticatedCustomers")}
        value="24"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        <span class="change-value">{"+20% "}</span>{$_("riskManagement.overview.vsPrevious30Days")}
    </Card>
</section>

<section id="losses-charts" class="mx-8 mt-4 grid grid-cols-1 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.exposure.title")}
        titleTooltip={$_("riskManagement.exposure.overviewExposureTooltip")}
        chartData={getExposureChangeOverTimeChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
        linkLabel={$_("riskManagement.exposure.exploreExposure")}
    />
</section>

<section class="middle-cards">
    <Card
        id="customer-credit-limits"
        text={$_("riskManagement.overview.customerCreditLimits")}
        textClasses="ctn-general-primary font-semibold text-base"
    >
        <div class="card-body-text">{$_("riskManagement.overview.customerCreditLimitsText")}</div>
        <Button
            on:click={() => navigate("/merchant/customers")}
            size="md"
            variant="secondaryColor"
            content={$_("riskManagement.overview.manageCreditLimits")}
        />
    </Card>
    <Card
        id="fraud-prevented"
        text={$_("riskManagement.overview.rejectedOrders")}
        textClasses="ctn-general-primary font-semibold text-base"
    >
        <div class="card-body-text">{$_("riskManagement.overview.rejectedOrdersText")}</div>
        <Button
            on:click={() => navigate("/merchant/orders?tab=rejected")}
            size="md"
            variant="secondaryColor"
            content={$_("riskManagement.overview.seeOrders")}
        />
    </Card>
</section>

<section id="losses-charts" class="mx-8 mt-4 grid grid-cols-2 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.exposure.creditLoses")}
        subtitle={$_("riskManagement.exposure.losesPerMonth")}
        subtitleTooltip={$_("riskManagement.fraudInsights.lossesPerMonthTooltip")}
        chartData={getLosesPerMonthChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
        showCustomLegend={false}
        linkLabel={$_("riskManagement.exposure.exploreLossMetrics")}
    />

    <RiskChart
        title="&nbsp;"
        subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
        subtitleTooltip={$_("riskManagement.fraudInsights.percentageLostTooltip")}
        chartData={getLostPercentageVolumeChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
    />
</section>

<section id="fraud-losses" class="mx-8 mt-4 grid grid-cols-2 mb-4 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.fraudInsights.fraudLosses")}
        subtitle={$_("riskManagement.fraudInsights.lossesPerMonth")}
        subtitleTooltip={$_("riskManagement.fraudInsights.lossesPerMonthTooltip")}
        chartData={getFraudLossesPerMonthChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
        showCustomLegend={false}
        linkLabel={$_("riskManagement.fraudInsights.exploreFraudInsights")}
    />

    <RiskChart
        title="&nbsp;"
        subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
        subtitleTooltip={$_("riskManagement.fraudInsights.percentageLostTooltip")}
        chartData={getFraudLostPercentageVolumeChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
    />
</section>

<style>
    .top-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin: 1.5rem 2rem 0 2rem;
    }

    .change-value {
        color: var(--ctnSemanticSuccessPrimary);
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .middle-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin: 1rem 2rem 0 2rem;
    }

    .card-body-text {
        color: var(--ctnGeneralPrimary);
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 1rem;
    }
</style>
