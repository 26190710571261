<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import Button from "../../components/Elements/Button.svelte";
    import Icon from "../../components/icons/Icon.svelte";
    import MetaWrapper from "../../components/metadata/MetaWrapper.svelte";
    import PageHeader from "../../components/pageHeader/PageHeader.svelte";
    import {
        account,
        showAccountAlert,
        actions as accountActions,
        signupComplete
    } from "../../store/merchant/account.store";
    import modalState from "../../store/modals.store";
    import { CREATE_UPDATE_USER_MERCHANT } from "../../static/modals";
    import AccountDetails from "./account/AccountDetails.svelte";
    import Team from "./account/Team.svelte";
    import SignupIncompleteModal from "../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import * as helpLinks from "../../services/helpCentreService";
    import environment from "@/services/environment";

    const helpMenuLinks = {
        [$_("helpLinks.accountSettingFaq")]: helpLinks.ACCOUNT_SETTING_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };
    const TAB_ACCOUNT_DETAILS = $_("account.tabs.accountDetails");
    const TAB_TEAM = $_("account.tabs.team");
    const setTab = (tab) => {
        selectedTab = tab;
        navigate(`/merchant/account?tab=${tab}`);
    };
    const getUrlSelectedTab = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlSelectedTab = urlParams.get("tab");
        return urlSelectedTab && TABS.includes(urlSelectedTab)
            ? urlSelectedTab
            : TAB_ACCOUNT_DETAILS;
    };

    const TABS = [TAB_ACCOUNT_DETAILS, TAB_TEAM];
    let showAccountAlertForTab = false;
    let selectedTab: string = getUrlSelectedTab();
    $: showAccountAlertForTab = selectedTab === TAB_TEAM ? false : $showAccountAlert;
</script>

<MetaWrapper title={$_("account.titles.accountDetailsTab")}>
    <div class="page-container">
        <PageHeader
            title={$_("account.titles.accountDetailsTab")}
            showAccountAlert={showAccountAlertForTab}
            on:alertClicked={() => {
                selectedTab = TAB_TEAM;
            }}
            newUser={!$account?.flags?.has_made_order}
            {helpMenuLinks}
        >
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <button
                        class="cursor-pointer px-6 mr-2 h-10 tab-header"
                        on:click={() => setTab(tab)}
                        class:selected={selectedTab === tab}
                    >
                        {tab}
                    </button>
                {/each}
            </div>
            <div slot="cta" class="h-9 w-36 sm:h-11 sm:w-40 text-sm">
                {#if selectedTab === TAB_TEAM}
                    <Button
                        size="medium"
                        on:click={() => {
                            modalState.actions.setModal(CREATE_UPDATE_USER_MERCHANT, {
                                submit_user: $signupComplete
                                    ? accountActions.createUser
                                    : modalState.actions.setModal("", {}, SignupIncompleteModal)
                            });
                        }}
                        bgColor="var(--primary-600)"
                    >
                        <div class="">
                            <Icon name="add-user" fill={"#ffffff"} size={18} />
                        </div>
                        {$_("account.team.addMember")}
                    </Button>
                {/if}
            </div>
        </PageHeader>
        <div class="content-container">
            {#if selectedTab === TAB_ACCOUNT_DETAILS}
                <AccountDetails />
            {:else if selectedTab === TAB_TEAM}
                <Team />
            {/if}
        </div>
    </div>
</MetaWrapper>

<style>
    .tab-headers {
        height: 2.5rem;
        display: flex;
        box-sizing: border-box;
    }
    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }
    .tab-header {
        line-height: 2.5rem;
        font-size: 14px;
    }

    .page-container {
        display: flex;
        flex-direction: column;
    }
</style>
