<script lang="ts">
    import { _ } from "svelte-i18n";

    import onboardingRoutes from "../../routes/onboarding.routes";
    import Router from "../../routes/CustomRouter.svelte";
    import b2bonboardingState from "../../store/b2bonboarding.store";
    import type { MerchantB2bOnboardingData } from "../../static/types";
    import Icon from "../../components/icons/Icon.svelte";

    let title = $_("b2bSignup.onboarding");

    let merchantDetails: MerchantB2bOnboardingData = {};

    $: b2bonboardingState.merchantOnboardingData.subscribe(
        (data: MerchantB2bOnboardingData) => (merchantDetails = data)
    );
</script>

<svelte:head>
    <title>{title}</title>
    <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
</svelte:head>

<div class="h-screen flex flex-col bg-gray safe-top safe-left safe-right safe-bottom relative">
    <div
        class="z-20 px-3 py-5 flex flex-row flex-wrap justify-between items-center sticky top-0 bg-gray"
    >
        <div class="flex text-right flex-col nav-back">
            <div
                class="relative w-8 h-8 cursor-pointer flex justify-center items-center bg-white rounded-full"
                id="back-button-to-close"
                on:keypress={() => {
                    window?.close();
                }}
                on:click={() => {
                    window?.close();
                }}
            >
                <Icon size={24} name="arrow-left" fill="#150E0B" />
            </div>

            <div
                class="relative w-8 h-8 cursor-pointer flex justify-center items-center bg-white rounded-full"
                id="back-button-on-step-2"
            >
                <Icon size={24} name="arrow-left" fill="#150E0B" />
            </div>
        </div>
        <div class="flex flex-row items-end">
            <img
                class="cursor-pointer m-auto"
                id="powered-logo"
                src="/pictures/powered-by-two.svg"
                alt="logo"
                width="150"
            />

            <div
                class="relative rekki-font-ocd w-16 h-8 cursor-pointer flex justify-center items-center bg-white rounded-full"
                id="skip-verification-button"
            >
                {$_("components.skip")}
            </div>
        </div>
    </div>
    <div class="flex flex-col justify-center w-full h-full grow pt-0">
        <Router routes={onboardingRoutes} />
    </div>
</div>

<style>
    .bg-gray {
        background: #e5e5e5;
    }
    :global(body) {
        background-color: #e5e5e5;
    }
    .rekki-font-ocd {
        font-family: var(--font-family-rekki-ocd);
    }
    #back-button-to-close {
        width: 2rem;
    }
    #back-button-on-step-2 {
        display: none;
    }
    #skip-verification-button {
        background: #cacaca;
        display: none;
    }
</style>
