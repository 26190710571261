<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import { account } from "../../../../store/merchant/account.store";
    import Button from "../../../../components/Elements/Button.svelte";
    import Icon from "../../../../components/icons/Icon.svelte";
    import type { CurrencyCode, IMerchantOrderInvalidLineItems } from "../../../../static/types";
    import utils, { type CurrencyFormatOptions } from "../../../../utilsV2/currency";
    import { openBankingDataRequired } from "../../../../store/merchant/creditlimit.store";
    import { taxNaming } from "../../../../store/merchant/account.store";
    import LineItem from "./LineItem.svelte";
    import environment from "@/services/environment";
    import countryName from "@/static/countryName";
    import { orderReplica } from "@/store/merchant/allOrders.store";

    export let line_items = [];
    export let gross_amount = 0;
    export let net_amount = 0;
    export let tax_amount = 0;
    export let discount_amount = 0;
    export let lineItemsError = "";
    export let availableCreditLimit: number;
    export let maxOrderValue: number;
    export let company: string;
    export let orderValueError: boolean = false;
    export let allowFallbackForOrder = false;
    export let merchantLegalName;

    export let merchantRecourseLimitValue: number;
    export let buyerRecourseLimitValue: number;

    export let invalidLineItems: IMerchantOrderInvalidLineItems[] = [];
    export let currency: CurrencyCode;
    export let merchantCountryCode: keyof typeof countryName;

    let enableMoreDetails: boolean = false;
    $: enableMoreDetails = $account?.flags?.show_line_item_more_details_on_order_creator;

    let orderValueErrorMessage: string;

    let maxOrderAllowed: number;

    const lineItemTemplate = {
        name: "",
        unit_price: null,
        quantity: null,
        tax_rate: null,
        tax_amount: 0,
        discount_amount: null,
        gross_amount: null,
        net_amount: 0,
        tax_class_name: "",
        quantity_unit: "pcs",
        type: "PHYSICAL",
        description: "",
        brand: null,
        categories: [],
        part_number: null,
        barcodeType: "",
        barcodeValue: ""
    };

    let taxRateOptions: string[] = [];

    $: currency && currencyUpdated();

    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(currency)(money, options);

    let gross_amount_display = formatMoney(gross_amount);
    let tax_amount_display = formatMoney(tax_amount);
    let net_amount_display = formatMoney(net_amount);
    let discount_amount_display = formatMoney(discount_amount);

    onMount(() => {
        if ($orderReplica && $orderReplica.lineItems) {
            line_items = [...$orderReplica.lineItems];
        } else {
            addItem();
        }
    });

    function currencyUpdated() {
        const key = currency == "EUR" ? `${currency}:${merchantCountryCode}` : `${currency}`;
        switch (key) {
            case "GBP":
                taxRateOptions = ["0", "5", "20"];
                break;
            case "SEK":
                taxRateOptions = ["0", "6", "12", "25"];
                break;
            case "NOK":
                taxRateOptions = ["0", "12", "15", "25"];
                break;
            case "EUR:NL":
                taxRateOptions = ["0", "9", "21"];
                break;
            case "EUR:ES":
                taxRateOptions = ["0", "15", "20", "25"];
                break;
            default:
                taxRateOptions = ["0", "12", "15", "25"];
                break;
        }
        line_items.forEach((item) => {
            item.tax_rate = $orderReplica ? String(item.tax_rate * 100) : null;
        });
        calculateTotals();
    }

    function addItem() {
        lineItemsError = "";
        line_items = [...line_items, { ...lineItemTemplate }];
        line_items.length > 1 &&
            setTimeout(() => {
                focusLastItem();
            }, 200);
    }

    function removeItem(index: number) {
        lineItemsError = "";
        line_items = [...line_items.slice(0, index), ...line_items.slice(index + 1)];
        calculateTotals();
    }

    function focusLastItem() {
        const lastIndex = line_items.length - 1;
        const elem = document.getElementById(`item_name_${lastIndex}`);
        elem && elem.focus();
    }

    function calculateTotals() {
        let temp_total_before_vat = 0;
        let temp_total_vat_amount = 0;
        let temp_discount = 0;

        line_items.forEach((item) => {
            temp_total_before_vat += +(
                item.unit_price * item.quantity -
                item.discount_amount
            ).toFixed(2);
            temp_total_vat_amount += Number(
                (!item.tax_rate
                    ? 0
                    : (item.unit_price * item.quantity - item.discount_amount) *
                      (item.tax_rate / 100)
                ).toFixed(2)
            );
            temp_discount += item.discount_amount;
        });

        gross_amount = Number((temp_total_before_vat + temp_total_vat_amount).toFixed(2));
        discount_amount = Number(temp_discount.toFixed(2));
        net_amount = Number(temp_total_before_vat.toFixed(2));
        tax_amount = Number(temp_total_vat_amount.toFixed(2));

        gross_amount_display = formatMoney(gross_amount);
        tax_amount_display = formatMoney(tax_amount);
        net_amount_display = formatMoney(net_amount);
        discount_amount_display = formatMoney(discount_amount);
    }

    function checkOrderValue(
        amount: number,
        maxValue: number,
        balance: number,
        merchantRecourseValue: number,
        buyerRecourseValue: number
    ) {
        // Show credit amount error only if credit balance and max order value are available...
        if (balance === undefined || maxValue === undefined) {
            return false;
        }
        maxOrderAllowed = Math.min(balance, maxValue);
        // check if merchant customer recourse fallback is disabled
        if ((!buyerRecourseValue || buyerRecourseValue == 0) && !$openBankingDataRequired) {
            // ... and order amount is exceeding the limits.
            orderValueErrorMessage = $_("lineItem.orderValueErrorMessage", {
                values: {
                    brandName: environment.branding.displayName,
                    maxOrderValue: formatMoney(maxOrderAllowed),
                    company
                }
            });
            return amount > maxOrderAllowed;
        } else if (amount > maxOrderAllowed && !$openBankingDataRequired) {
            if (buyerRecourseValue >= amount && merchantRecourseValue >= amount) {
                return false;
            } else if (buyerRecourseValue <= amount && merchantRecourseValue > amount) {
                orderValueErrorMessage = $_("lineItem.recourseFallbackErrorMessage", {
                    values: { company }
                });
                return true;
            } else {
                orderValueErrorMessage = $_("lineItem.totalRecourseErrorMessage", {
                    values: { supportEmail: environment.branding.supportEmail }
                });
                return true;
            }
        }
        return false;
    }

    $: orderValueError = checkOrderValue(
        gross_amount,
        maxOrderValue,
        availableCreditLimit,
        merchantRecourseLimitValue,
        buyerRecourseLimitValue
    );
</script>

<div class="line-item-container">
    {#if lineItemsError !== ""}
        <div
            class="inline-block rounded-2xl px-6 py-3 mb-6 box-border shadow-sm h-full relative error-box"
        >
            <div class="flex flex-column">
                <div>
                    <Icon size={24} name="errorinfo" fill="#B42318" />
                </div>
                <div class="pl-2">
                    {lineItemsError}
                </div>
            </div>
        </div>
    {/if}
    <p class="text-lg text-black-dark font-semibold mr-3">{$_("lineItem.title")}</p>
    {#if line_items.length > 0}
        <div class={enableMoreDetails ? "grid-container-8-cols" : "grid-container-7-cols"}>
            <div class="text-sm ctn-general-primary items-header pl-10">
                &nbsp;&nbsp; {$_("lineItem.itemNameHeader")}
            </div>
            <div class="text-sm ctn-general-primary items-header">
                {$_("lineItem.unitPriceHeader")}
            </div>
            <div class="text-sm ctn-general-primary items-header">
                {$_("lineItem.quantityHeader")}
            </div>
            <div class="text-sm ctn-general-primary items-header">
                {$_("lineItem.discountHeader")}
            </div>
            <div class="text-sm ctn-general-primary items-header">
                {$_("lineItem.taxRateHeader")}
            </div>
            <div class="text-sm ctn-general-primary items-header">{$_("lineItem.totalHeader")}</div>
            {#if enableMoreDetails}<div class="text-sm ctn-general-primary items-header">
                    <!-- Empty header for the "More Details" button -->
                </div>{/if}
            <div class="items-header empty-header" />
            {#each line_items as item, index}
                <LineItem
                    {item}
                    {index}
                    bind:currency
                    bind:lineItemsError
                    bind:invalidLineItems
                    bind:taxRateOptions
                    bind:line_items
                    {calculateTotals}
                    removeItem={() => removeItem(index)}
                />
            {/each}
        </div>
    {/if}
    <div class="w-36 add-item">
        <Button
            size="large"
            label={$_("lineItem.addItemButton")}
            color={"var(--primary-700)"}
            hoverColor={"var(--primary-700)"}
            bgColor={"#FFFFFF"}
            hoverBg={"#FFFFFF"}
            borderColor={"#FFFFFF"}
            on:click={addItem}
        />
    </div>

    <hr class="mt-2" />

    {#if line_items.length > 0}
        <div class="flex mt-4 justify-end text-right">
            <div class="w-1/3 flex flex-wrap text-sm text-dark">
                <div class="w-full pb-2 lg:w-1/2 text-base border-bottom">
                    {$_("lineItem.subtotalLabel")}
                </div>
                <div class="w-full pb-2 lg:w-1/2 text-base font-bold border-bottom">
                    {net_amount_display}
                </div>
                {#if discount_amount > 0}
                    <div class="w-full pb-2 lg:w-1/2 text-base border-bottom">
                        {$_("lineItem.totalDiscountLabel")}
                    </div>
                    <div class="w-full pb-2 lg:w-1/2 text-base border-bottom">
                        - {discount_amount_display}
                    </div>
                {/if}
                <div class="w-full pb-2 lg:w-1/2 text-base border-bottom">{$taxNaming}:</div>
                <div class="w-full pb-2 lg:w-1/2 text-base border-bottom">
                    {tax_amount_display}
                </div>
                <div class="w-full pb-2 lg:w-1/2 text-base font-bold border-none">
                    {$_("lineItem.totalLabel")}
                </div>
                <div
                    class="w-full pb-2 lg:w-1/2 text-xl font-bold border-none flex justify-end items-center"
                >
                    {#if orderValueError && !allowFallbackForOrder}
                        <div class="pr-2">
                            <Icon size={24} name="errorinfo" fill="#B42318" />
                        </div>
                    {/if}
                    {gross_amount_display}
                </div>
            </div>
        </div>
        {#if orderValueError}
            {#if allowFallbackForOrder}
                <div
                    class="w-full rounded-2xl px-6 py-3 mb-6 box-border shadow-sm h-full relative warning-box"
                >
                    <div class="flex flex-column">
                        <div>
                            <Icon size={24} name="warningInfo" fill="#B54708" />
                        </div>
                        <div class="pl-2">
                            {$_("lineItem.creditWarning", {
                                values: {
                                    brandName: environment.branding.displayName,
                                    merchantLegalName
                                }
                            })}
                        </div>
                    </div>
                </div>
            {:else}
                <div
                    class="w-full rounded-2xl px-6 py-3 mb-6 box-border shadow-sm h-full relative error-box"
                >
                    <div class="flex flex-column">
                        <div>
                            <Icon size={24} name="errorinfo" fill="#B42318" />
                        </div>
                        <div class="pl-2">
                            {orderValueErrorMessage}
                        </div>
                    </div>
                </div>
            {/if}
        {/if}
    {/if}
</div>

<style>
    * :global(.add-item button) {
        text-align: left;
    }

    * :global(.add-item button:focus) {
        text-decoration: underline;
        background: transparent;
    }

    .add-item {
        width: 4.2rem;
    }

    .grid-container-7-cols {
        display: grid;
        grid-template-columns:
            minmax(100px, 1.3fr) minmax(120px, 1fr) minmax(100px, 1fr) minmax(80px, 0.7fr)
            minmax(100px, 0.7fr) minmax(120px, 1.3fr) 24px;
        padding: 15px 0 10px 0;
        gap: 0 10px;
    }

    .grid-container-8-cols {
        display: grid;
        grid-template-columns:
            minmax(100px, 1.3fr) minmax(120px, 1fr) minmax(100px, 1fr) minmax(80px, 0.7fr)
            minmax(100px, 0.7fr) minmax(120px, 1.3fr) 24px 24px;
        padding: 15px 0 10px 0;
        gap: 0 10px;
    }

    .items-header {
        background: var(--bgGeneralTertiary);
        border-radius: 8px;
        height: 38px;
        gap: 0;
        padding: 9px 2px;
        margin-right: -25px;
    }

    .empty-header {
        margin-right: 0;
    }

    .error-box {
        background: #fef3f2;
        border: 2px solid #f97066;
        border-radius: 8px;
        color: var(error-600);
    }

    .warning-box {
        background: #fffaeb;
        border: 1px solid #b54708;
        border-radius: 8px;
        color: #b54708;
    }

    * :global(.name-field > div > input) {
        text-overflow: ellipsis;
        padding-right: 1rem;
    }

    .line-item-container {
        position: relative;
        display: flex;
        flex-direction: column;
    }
</style>
