<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onMount } from "svelte";

    import utils from "../../../../utilsV2/currency";
    import { DATA_TYPE } from "../productTable.constants";
    import {
        NotificationType,
        type IMorderSummary,
        type IOrderItem
    } from "../../../../static/types";
    import Icon from "../../../../components/icons/Icon.svelte";
    import Input from "../../../../components/Elements/Input.svelte";
    import Selector from "../../../../components/Elements/Selector.svelte";
    import { ORDER_MAIN, GET_LATENT_ORDER_DETAILS } from "../../../../static/endPoints";
    import { getBaseUrl } from "../../../../utils/functions";
    import API from "../../../../services/apiService";
    import notificationState from "../../../../store/notifications.store";
    import Loading from "../../../../components/Elements/Loading.svelte";
    import modalState from "../../../../store/modals.store";
    import environment from "@/services/environment";

    const dispatch = createEventDispatcher();
    export let order: IMorderSummary | undefined;
    export let title = "";
    export let columns = [];
    export let items: IOrderItem[] = [];
    export let locale = "";
    export let currency: any;
    export let taxNaming: string = "VAT";
    export let shippingAddress: {} = {};
    export let billingAddress: {} = {};
    export let maxOrderValue: number = -1;
    export let orderJSON: {} = {};

    let updatedLineItems: IOrderItem[] = [];
    let filterItems: string[] = [];

    let netAmount: number = 0;
    let taxAmount: number = 0;
    let grossAmount: number = 0;
    let discountAmount: number = 0;
    let fulfilledGrossAmount: number = 0;

    let disabledEditClass: string = "-disabled";
    let displayTableError: boolean = false;
    let tableErrorText: string = "";
    let errorElements: { index: number; colName: string }[] = [];
    let sendingEdit: boolean = false;

    const merchantOrderReference = order.merchantOrderReference;
    const purchaseOrderNumber = order.purchaseOrderNumber;
    const project = order.project;

    onMount(() => {
        updatedLineItems = items.map((obj) => ({ ...obj }));
        fulfilledGrossAmount = Number(order.grossAmount);
        switch (currency) {
            case "GBP":
                filterItems = ["0", "5", "20"];
                break;
            case "SEK":
                filterItems = ["0", "6", "12", "25"];
                break;
            case "NOK":
                filterItems = ["0", "12", "15", "25"];
                break;
            default:
                filterItems = ["0", "12", "15", "25"];
                break;
        }
        calculateTotals();
    });

    $: modalState.modals((values) => {
        if (values.props?.isEdited !== undefined) {
            sendingEdit = false;
            if (values.props?.isEdited) {
                cancelEdit(true);
            } else {
                disabledEditClass = "";
            }
        }
    });

    function calculateTotals() {
        netAmount = 0;
        taxAmount = 0;
        grossAmount = 0;
        discountAmount = 0;
        updatedLineItems.forEach((lineItem) => {
            netAmount += lineItem.net_amount;
            taxAmount += lineItem.tax_amount;
            grossAmount += lineItem.gross_amount;
            discountAmount += Number(lineItem.discount_amount);
        });
        if (
            ((order.state === "FULFILLED" || order?.status === "PARTIAL") &&
                Number(grossAmount.toFixed(2)) !== Number(fulfilledGrossAmount.toFixed(2))) ||
            (maxOrderValue > 0 && grossAmount > maxOrderValue * 1.1)
        ) {
            disabledEditClass = "-disabled";
        }
    }

    function cancelEdit(saveEdits = false) {
        let editedData = {};
        if (saveEdits) {
            editedData = {
                subtotal: netAmount,
                tax: taxAmount,
                total: grossAmount
            };
        }
        netAmount = 0;
        taxAmount = 0;
        grossAmount = 0;
        discountAmount = 0;
        updatedLineItems = [];
        disabledEditClass = "";
        dispatch("cancelEdit", editedData);
    }

    const prepareData = () => {
        const gross_amount = Number(grossAmount.toFixed(2));
        const net_amount = Number(netAmount.toFixed(2));
        const discount_amount = Number(discountAmount.toFixed(2));
        const tax_amount = Number(taxAmount.toFixed(2));

        const data: any = {
            gross_amount,
            net_amount,
            currency,
            discount_amount,
            tax_amount,
            line_items: updatedLineItems,
            billing_address: billingAddress,
            shipping_address: shippingAddress
        };

        return data;
    };

    function addItem() {
        updatedLineItems.push({
            id: String(new Date().getTime()),
            name: "",
            description: "",
            discount_amount: 0,
            quantity: 0,
            unit_price: 0,
            tax_rate: 0,
            net_amount: 0,
            tax_amount: 0,
            gross_amount: 0,
            tax_class_name: "",
            quantity_unit: "pcs",
            type: "PHYSICAL"
        });
        updatedLineItems = updatedLineItems;
        disabledEditClass = "";
    }

    function deleteItem(index) {
        updatedLineItems.splice(index, 1);
        updatedLineItems = updatedLineItems;
        disabledEditClass = "";
        calculateTotals();
    }

    function sendEditedOrder() {
        if (!validateLineItems()) {
            const baseUrl = getBaseUrl();

            disabledEditClass = "-disabled";

            sendingEdit = true;

            if (order?.status === "QUOTED") {
                const url = `${baseUrl}${GET_LATENT_ORDER_DETAILS(order.orderID)}`;
                const data = {
                    ...orderJSON,
                    gross_amount: Number(grossAmount.toFixed(2)),
                    net_amount: Number(netAmount.toFixed(2)),
                    discount_amount: Number(discountAmount.toFixed(2)),
                    tax_amount: Number(taxAmount.toFixed(2)),
                    line_items: updatedLineItems
                };
                sendOrder("patch", url, data);
            } else if (order.state === "FULFILLED" || order?.status === "PARTIAL") {
                if (grossAmount !== Number(order.grossAmount)) {
                    displayTableError = true;
                    tableErrorText = $_("order.edit.error_order_value");
                    disabledEditClass = "-disabled";
                    sendingEdit = false;
                    return;
                }
                modalState.actions.setModal("EDIT_INVOICE", {
                    invoiceID: order?.invoice_details?.id,
                    order,
                    billingAddress,
                    shippingAddress,
                    merchantOrderReference,
                    purchaseOrderNumber,
                    project,
                    lineItems: updatedLineItems
                });
            } else {
                const data = prepareData();
                const url = `${baseUrl}${ORDER_MAIN(order.orderID)}`;
                sendOrder("put", url, data);
            }
        }
    }

    function sendOrder(method, url, data) {
        let request;

        if (method === "put") {
            request = API.put(url, data);
        } else if (method === "patch") {
            request = API.patch(url, data);
        } else {
            throw new Error(`Invalid method: ${method}`);
        }

        request
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.edit.notification_success")
                );
                items = updatedLineItems.map((obj) => ({ ...obj }));
                cancelEdit(true);
                sendingEdit = false;
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("order.edit.notification_error"),
                    null,
                    environment.branding.hasSupportChat
                        ? $_("order.edit.notification_error_detail")
                        : $_("order.edit.notification_error_detail_without_chat")
                );
                disabledEditClass = "";
                sendingEdit = false;
            });
    }

    function formatProductValue(product: IOrderItem, index, column) {
        const rawValue = column.propertyName ? product[column.propertyName] : index + 1;
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return formatAmount(rawValue);
            case DATA_TYPE.PERCENT:
                return formatPercent(rawValue);
            default:
                return rawValue;
        }
    }

    function formatAmount(amount: number | string) {
        return utils.formatCurrency(currency)(amount, { locale });
    }

    function formatPercent(aNumber: number | string) {
        return (typeof aNumber === "string" ? parseFloat(aNumber) : aNumber).toLocaleString("en", {
            style: "percent"
        });
    }

    function itemChanged(columnType: string, product: IOrderItem, index, event) {
        const itemIndex = errorElements.findIndex(
            (element) => element.index === index && element.colName === columnType
        );

        if (itemIndex !== -1) {
            errorElements.splice(itemIndex, 1);
            errorElements = errorElements;
        }

        if (errorElements.length === 0) {
            displayTableError = false;
            disabledEditClass = "";
        }

        if (
            columnType === "quantity" ||
            columnType === "unit_price" ||
            columnType === "tax_rate" ||
            columnType === "discount_amount"
        ) {
            switch (columnType) {
                case "quantity":
                    product.quantity = event.detail.value;
                    break;
                case "unit_price":
                    product.unit_price = event.detail.value;
                    break;
                case "tax_rate":
                    product.tax_rate = event.detail.value / 100;
                    break;
                case "discount_amount":
                    product.discount_amount = event.detail.value;
                    break;
            }

            product.net_amount =
                Math.round(
                    (product.quantity * product.unit_price - product.discount_amount) * 100
                ) / 100;
            product.tax_amount = Math.round(product.net_amount * product.tax_rate * 100) / 100;
            product.gross_amount = Number((product.net_amount + product.tax_amount).toFixed(2));
            updatedLineItems = updatedLineItems;
        } else {
            product[columnType] = event.detail.value;
            updatedLineItems = updatedLineItems;
        }
        calculateTotals();
    }

    function validateLineItems() {
        errorElements = [];
        const errorFields = [
            { field: "name", condition: (item) => item.name === "" },
            { field: "quantity", condition: (item) => item.quantity < 0 },
            { field: "unit_price", condition: (item) => item.unit_price < 0 },
            { field: "tax_rate", condition: (item) => item.tax_rate < 0 },
            { field: "discount_amount", condition: (item) => item.discount_amount < 0 }
        ];

        const errors = updatedLineItems.flatMap((lineItem, index) =>
            errorFields
                .filter(({ condition }) => condition(lineItem))
                .map(({ field }) => ({ index, colName: field }))
        );

        errorElements.push(...errors);

        if (errors.length > 0) {
            displayTableError = true;
            tableErrorText = $_("order.edit.table_error");
            disabledEditClass = "-disabled";
            return true;
        }

        return false;
    }
</script>

<div class="scrollbar">
    <div class="main-bar">
        <div class="card-title">
            <div class="card-title-text">{title}</div>
            <div class="card-title-subtext">
                {$_("order.edit.title_subtext")}
                <strong
                    >{maxOrderValue > 0
                        ? $_("order.edit.order_value_limit", {
                              values: { value: formatAmount(maxOrderValue * 1.1) }
                          })
                        : ""}
                </strong>
            </div>
        </div>
        <div class="button-container">
            <button on:click={() => cancelEdit(false)}>{$_("components.cancel")}</button>
        </div>
    </div>

    <div class="product-table-wrap">
        <table class="table">
            <thead class="table-head">
                {#each columns as column}
                    <th
                        class="table-header table-header--left"
                        class:extend-margin={column.label === $_("order.edit.invoice_number") &&
                            updatedLineItems.length > 1}
                        style="width: {column.width}"
                    >
                        {@html column.label}
                    </th>
                {/each}
                <th class="table-header table-header--left delete-col" style="width: 50px"></th>
            </thead>
            {#if displayTableError}
                <tr class="header-error-row">
                    <td colspan="9">
                        <div class="flex px-5 gap-2">
                            <Icon name="cross-circle" size={22} fill="#912018" />
                            {tableErrorText}
                        </div>
                    </td>
                </tr>
            {/if}
            {#each updatedLineItems as item, index}
                <tr class="table-row">
                    {#each columns as column}
                        {#if column.propertyName && column.propertyName !== "net_amount" && column.propertyName !== "gross_amount" && column.propertyName !== "tax_rate"}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                            >
                                <Input
                                    id={`${column.propertyName}${index}`}
                                    type="text"
                                    value={item[column.propertyName]}
                                    placeholder="0"
                                    on:change={(event) =>
                                        itemChanged(column.propertyName, item, index, event)}
                                    showInvalidError={errorElements.some(
                                        (element) =>
                                            element.index === index &&
                                            element.colName === column.propertyName
                                    )}
                                    showBorder={false}
                                    toolTipContent={column.propertyName === "name"
                                        ? item[column.propertyName]
                                        : ""}
                                />
                            </td>
                        {:else if column.propertyName && column.propertyName === "tax_rate"}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                            >
                                <Selector
                                    required
                                    options={filterItems}
                                    value={(item[column.propertyName] * 100).toString()}
                                    id={`tax_rate_${index}`}
                                    showArrow={true}
                                    placeholder="-"
                                    on:change={(event) =>
                                        itemChanged(column.propertyName, item, index, event)}
                                    size="44px"
                                    backgroundColor="white"
                                />
                            </td>
                        {:else}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                                class:table-cell--editable={column.propertyName &&
                                    column.propertyName !== "net_amount" &&
                                    column.propertyName !== "gross_amount"}
                            >
                                {#if column.propertyName}
                                    {#if column.propertyName == "discount_amount" || column.propertyName == "quantity" || column.propertyName == "unit_price"}
                                        <Input
                                            type="number"
                                            value={formatProductValue(item, index, column)}
                                            disabled={true}
                                            toolTipContent={column.propertyName === "tax_rate"
                                                ? ""
                                                : $_("order.edit.data_auto_calculated")}
                                        />
                                    {:else}
                                        <Input
                                            type="text"
                                            value={formatProductValue(item, index, column)}
                                            disabled={true}
                                            toolTipContent={column.propertyName === "tax_rate"
                                                ? ""
                                                : $_("order.edit.data_auto_calculated")}
                                        />
                                    {/if}
                                {:else}
                                    {formatProductValue(item, index, column)}
                                {/if}
                            </td>
                        {/if}
                    {/each}
                    <td class="table-header table-header--left delete-col" style="width: 50px">
                        <button
                            class="delete-button"
                            disabled={updatedLineItems.length <= 1}
                            on:click={() => deleteItem(index)}
                            ><Icon name="delete" fill={"#1D2939"} /></button
                        >
                    </td>
                </tr>
            {/each}
            {#if !(order.state === "FULFILLED" || order?.status === "PARTIAL")}
                <tr>
                    <td colspan="2" class="table-cell summary-cell table-cell--left table-cell">
                        <button on:click={addItem}>
                            <div class="add-button">
                                <Icon name="add" fill={"var(--primary-500)"} />
                                {$_("order.edit.add_product")}
                            </div></button
                        >
                    </td>
                    <td colspan="7" class="table-cell summary-cell" />
                </tr>
            {/if}
            <tr>
                <td colspan="5" />
                <td class="table-cell summary-cell table-cell--right">
                    {$_("order.edit.subtotal")}
                </td>
                <td class="summary-cell" />
                <td colspan="2" class="table-cell summary-cell table-cell--right">
                    {formatAmount(netAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="5" />
                <td class="table-cell summary-cell table-cell--right">{taxNaming}:</td>
                <td class="table-cell summary-cell table-cell--right" />
                <td colspan="2" class="table-cell summary-cell table-cell--right table-cell">
                    {formatAmount(taxAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="5" />
                <td class="table-cell table-cell--right table-cell table-cell--bold">
                    {$_("order.edit.total")}
                </td>
                <td colspan="3" class="table-cell table-cell--right table-cell table-cell--bold">
                    <div class="total-container">
                        {formatAmount(grossAmount)}
                        {#if maxOrderValue > 0 && grossAmount > maxOrderValue * 1.1}
                            <Icon name="alert-2" size={16} fill="#912018" />
                        {/if}
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="credit-info-section">
        {#if (order.state === "FULFILLED" || order?.status === "PARTIAL") && Number(grossAmount.toFixed(2)) !== Number(fulfilledGrossAmount.toFixed(2))}
            <div class="edit-error">
                <Icon name="cross-circle" size={20} fill="var(--error-700)" />
                {$_("order.edit.error_order_value")}
            </div>
        {:else if maxOrderValue > 0 && grossAmount > maxOrderValue * 1.1}
            <div class="edit-error">
                <Icon name="cross-circle" size={20} fill="var(--error-700)" />
                {$_("order.edit.order_value_exceeding", {
                    values: { limit: formatAmount(maxOrderValue * 1.1) }
                })}
            </div>
        {:else}
            <div class="edit-success">
                <Icon name="check-circle" size={20} fill="var(--success-800)" />
                {grossAmount < maxOrderValue
                    ? $_("order.edit.order_value_accepted")
                    : $_("order.edit.order_likely_accepted")}
            </div>
        {/if}
    </div>
    <div class="edit-buttons-area-container">
        <button
            class="button button--edit-full{disabledEditClass}"
            disabled={disabledEditClass !== ""}
            id="edit_order"
            on:click={sendEditedOrder}
        >
            {#if sendingEdit}
                <Loading />
            {:else}
                {$_("components.save")}
            {/if}
        </button>
    </div>
</div>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .scrollbar {
        overflow: auto;
    }

    .card-title {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 24px;
    }

    .card-title-text {
        font-weight: 700;
        line-height: 24px;
    }

    .card-title-subtext {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .delete-icon {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);

        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuarterary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &.extend-margin {
            padding-left: 56px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-row {
        border-bottom: 1px solid var(--gray-200);
        height: 76px;
    }

    .delete-col {
        padding-top: 5px;
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--bold {
            font-weight: 700;
            color: var(--gray-800);
        }

        &--editable {
            padding-right: 1.5rem;
        }
    }

    .summary-cell {
        border-bottom: 1px solid var(--gray-200);
    }

    .header-error-row {
        color: #912018;
        background-color: #fef3f2;
        height: 2.5rem;
        padding: 0.5rem;
    }

    .total-container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
    }

    .credit-info-section {
        display: flex;
        gap: 8px;
        margin-left: auto;
        justify-content: end;
        padding-right: 24px;
        padding-bottom: 24px;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .edit-error {
        display: flex;
        gap: 0.5rem;
        border-radius: 4px;
        background: var(--error-50);
        color: var(--error-700);
        padding: 0.5rem;
        align-items: center;
    }

    .edit-success {
        display: flex;
        gap: 0.5rem;
        border-radius: 4px;
        background: var(--success-50);
        color: var(--success-800);
        padding: 0.5rem;
        align-items: center;
    }

    .edit-buttons-area-container {
        display: flex;
        gap: 8px;
        margin-left: auto;
        float: right;
        padding-right: 24px;
        padding-bottom: 24px;
    }

    .button {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 20px;
        height: 40px;
        width: 110px;
        justify-content: center;

        &--edit-full {
            color: var(--basic);
            background-color: var(--primary-600);
        }

        &--edit-full-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
            pointer-events: none;
        }
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
        padding: 0.7rem;

        color: var(--primary-600);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .add-button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: var(--primary-500);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
    }

    .delete-button:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>
