<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Circle } from "svelte-loading-spinners";

    import { getChartGroupLabel } from "../../../utils/functions";
    import MixedChart from "../../../components/chart/MixedChart.svelte";
    import type { IDashboardChartValue, IDashboardOrderStateData } from "@/static/types";
    import { loading } from "../../../store/merchant/dashboard.store";
    import environment from "@/services/environment";

    export let data: IDashboardOrderStateData = {};
    export let refundData: IDashboardOrderStateData = {};
    export let currency = "";
    type ChartType = "TOTAL_SALES" | "SALES_VOLUME";
    let chartType: ChartType = "TOTAL_SALES";

    let loadingChart: boolean = true;

    const charts = {
        TOTAL_SALES: {
            title: $_("dashboard.chart.totalSalesTitle"),
            lineData: [],
            label: [],
            hoverStrings: [$_("status.fulfilled"), $_("status.refunded")],
            yAxisTitle: currency,
            colours: [environment.branding.chartMainColor, environment.branding.chartAltColor]
        },
        SALES_VOLUME: {
            title: $_("dashboard.chart.salesVolumeTitle"),
            lineData: [],
            label: [],
            hoverStrings: [$_("dashboard.chart.ordersPlaced")],
            yAxisTitle: "",
            colours: [environment.branding.chartMainColor]
        }
    };

    $: {
        let totalSales: number[] = [];
        let salesVolume: number[] = [];
        let totalSalesLabel: string[] = [];
        let salesVolumeLabel: string[] = [];
        let totalRefunds: number[] = [];

        // reverse order as they come date desc from checkout-api
        // TODO update BE to return date asc
        data.list_state_total?.reverse();
        data.list_state_volume?.reverse();
        refundData?.list_state_total?.reverse();

        totalSalesLabel = getChartGroupLabel(data.list_state_total);
        totalSales = data?.list_state_total?.map((value: IDashboardChartValue) => {
            const fulfilledValue = Number(value.amount);
            return fulfilledValue < 0 ? 0 : fulfilledValue;
        });
        totalRefunds = refundData?.list_state_total?.map((value: IDashboardChartValue) => {
            return Math.abs(Number(value.amount));
        });

        salesVolumeLabel = getChartGroupLabel(data.list_state_volume);
        salesVolume = data?.list_state_volume?.map((value: IDashboardChartValue) => {
            return Number(value.amount);
        });

        charts.TOTAL_SALES.lineData = [totalSales, totalRefunds];
        charts.TOTAL_SALES.label = totalSalesLabel;
        charts.SALES_VOLUME.lineData = [salesVolume];
        charts.SALES_VOLUME.label = salesVolumeLabel;
        charts.TOTAL_SALES.yAxisTitle = currency;
        reloadChart();
    }

    function reloadChart() {
        loadingChart = false;
        setTimeout(() => {
            loadingChart = true;
        }, 10);
    }
    function changeType(value: string) {
        chartType = value as ChartType;
        reloadChart();
    }
</script>

<div class="flex ctn-interactive-general-disabled font-bold" style="gap:1rem;">
    {#each Object.entries(charts) as [key, chart]}
        <button
            class="cursor-pointer"
            class:ctn-general-primary={chartType === key}
            on:click={() => changeType(key)}
        >
            {chart.title}
        </button>
    {/each}
</div>
<div class="mt-6 relative flex flex-1">
    {#if loadingChart}
        <MixedChart
            showTotal={false}
            showCustomLegend={false}
            showDefaultLegend={true}
            {...charts[chartType]}
        />
    {/if}
    {#if $loading}
        <div class="chart_loading absolute top-0 w-full h-full flex items-center">
            <div class="m-auto">
                <Circle color={environment.branding.chartMainColor} />
            </div>
        </div>
    {/if}
</div>
