<script lang="ts">
    import { _ } from "svelte-i18n";

    import { signupComplete } from "@/store/merchant/account.store";
    import Icon from "../../components/icons/Icon.svelte";
    import { ACCOUNT_SETUP } from "@/static/modals";
    import modalState from "@/store/modals.store";
    import { navigate } from "svelte-routing";
    import { createEventDispatcher } from "svelte";
    import type { IBanner } from "../../static/types";
    import { NotificationTypeIcons } from "../../static/constant";

    const dispatch = createEventDispatcher();

    export let showAccountAlert: boolean = false;
    export let pageBanner: IBanner = null;

    let pageBannerColor = "";
    let pageBannerIcon;

    $: if (pageBanner) {
        const theme = pageBanner.type.toLowerCase();
        pageBannerColor = `var(--${theme}-800)`;
        pageBannerIcon = NotificationTypeIcons[pageBanner.type];
    }
</script>

{#if !$signupComplete}
    <div class="banner banner--important">
        <div style="display:flex;gap: 0.5rem;align-items: center;">
            <Icon size={16} name="errorinfo" fill="var(--warning-900)" />
            <p style="font-size:14px">
                {@html $_("pageHeader.statementDemoBanner")}
                <button
                    class="text-link"
                    on:click={() => modalState.actions.setModal(ACCOUNT_SETUP, {})}
                >
                    <strong>{$_("pageHeader.statementSignup")}</strong>
                </button>
            </p>
        </div>
    </div>
{:else if showAccountAlert}
    <div class="banner banner--important">
        <div style="display:flex;gap: 0.5rem;align-items: center;">
            <Icon size={16} name="errorinfo" fill="var(--warning-900)" />
            <strong>{$_("pageHeader.accountDetailsMissing")}</strong>
            {$_("pageHeader.incompleteFields")}
            <button
                class="text-link"
                on:click={() => {
                    navigate("/merchant/account?tab=Team");
                    dispatch("alertClicked");
                }}
                ><strong class="underline cursor-pointer">
                    {$_("pageHeader.completeSignup")}</strong
                >
            </button>
        </div>
    </div>
{/if}
{#if pageBanner && !pageBanner?.closed}
    <div class="banner banner--{pageBanner.type.toLowerCase()}">
        <div style="display:flex;gap: 0.5rem;">
            <svelte:component this={pageBannerIcon} {...{ size: 20, fill: pageBannerColor }} />
            <div>
                <strong>{pageBanner.header || ""}</strong>
                {pageBanner.body}
                {#if pageBanner.textLink}
                    <button
                        class="text-link"
                        on:click={() => {
                            navigate(pageBanner.textLink);
                        }}
                        ><strong class="underline cursor-pointer">
                            {$_("pageHeader.completeSignup")}</strong
                        ></button
                    >
                {/if}
            </div>
        </div>
        <div style="display:flex">
            <button on:click={() => (pageBanner.closed = true)}>
                <Icon name="cross" fill={"var(--gray-400)"} size={20} />
            </button>
        </div>
    </div>
{/if}

<style lang="scss">
    .text-link strong {
        color: var(--warning-700);
        text-decoration: underline;
    }
    .text-link:hover strong {
        color: var(--warning-800);
    }
    .small-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
    }
    .banner {
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 2rem 0.5rem 2rem;
        font-weight: 500;
        font-size: 14px;
        justify-content: space-between;

        &--important {
            color: var(--gray-800);
            background-color: var(--warning-200);
        }

        &--success {
            color: var(--success-800);
            background-color: var(--success-50);
        }
        &--warning {
            color: var(--warning-800);
            background-color: var(--warning-50);
        }
        &--error {
            color: var(--error-800);
            background-color: var(--error-50);
        }
    }
</style>
