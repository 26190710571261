<script lang="ts">
    import { onMount } from "svelte";
    import {
        buyerData,
        invoices,
        invoicesSelectedCompany,
        buyerStoreMethods
    } from "../../../store/buyer/account.store";
    import Selector from "../../../components/account/Selector.svelte";
    import InvoicesTable from "./InvoicesTable.svelte";
    import {
        filterPaidInvoicesTable,
        filterUnpaidInvoicesTable,
        type IInvoiceObject
    } from "./invoiceUtils";
    import Search from "../../../components/search/Search.svelte";
    import { titleCase } from "../../../utilsV2/helpers";
    import { BASE_URL } from "../../../static/endPoints";
    import modalState from "../../../store/modals.store";
    import StatementOfAccountModal from "../../../components/modals/StatementOfAccountModal.svelte";
    import type { ISelectorInputField } from "../../../static/types";

    const TABS = ["All", "Unpaid and partially paid", "Paid"];

    const setTab = (tab) => (selectedTab = tab);

    let selectedTab: string = "All";
    const ALL_COMPANIES = "All Companies";

    $: allRows = $invoices;
    let activeRows: IInvoiceObject[] = [];
    let searchRows: IInvoiceObject[] = [];
    let searchValue: string = "";
    let changedValue: string = "";

    const buyerCompanies: Map<string, string> = new Map([[ALL_COMPANIES, ALL_COMPANIES]]);
    let selectedCompanyValue = ALL_COMPANIES;
    const merchantCompanies: Map<string, string> = new Map();
    let selectorInput: ISelectorInputField;
    let paymentStatus: string = null;

    $: if ($buyerData?.invoice_companies?.size > 0) {
        $buyerData.invoice_accounts_summary.forEach((merchantBuyer) => {
            merchantCompanies.set(merchantBuyer.merchant_id, merchantBuyer.merchant_display_name);
            buyerCompanies.set(
                `${merchantBuyer.buyer_company_name}:${merchantBuyer.buyer_organization_number}:${merchantBuyer.buyer_company_country_code}`,
                titleCase([merchantBuyer.buyer_company_name])[0]
            );
            selectorInput = {
                options: buyerCompanies,
                placeholder: ALL_COMPANIES
            };
        });
    }

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }

        if (searchValue == "") {
            searchRows = activeRows;
        } else {
            searchRows = activeRows.filter(
                (row) =>
                    row["Merchant details"].tableItemBold
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    row["Invoice №"].tableItem
                        .toLowerCase()
                        .startsWith(searchValue.toLowerCase()) ||
                    row["Invoice №"].subItem.toLowerCase().startsWith(searchValue.toLowerCase()) ||
                    row["Purchased by"].tableItem.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
    }

    $: if (changedValue) {
        invoicesSelectedCompany.set(selectedCompanyValue);
        buyerStoreMethods.getBuyerInvoices();
        merchantCompanies.clear();
        $buyerData.invoice_accounts_summary?.forEach((merchantBuyer) => {
            const [companyName, orgNumber] = selectedCompanyValue.split(":");
            if (
                selectedCompanyValue.startsWith("All") ||
                (companyName == merchantBuyer.buyer_company_name &&
                    orgNumber == merchantBuyer.buyer_organization_number)
            ) {
                // filter available merchants for dropdown list in invoice download, to those that are applicable to the buyer
                merchantCompanies.set(
                    merchantBuyer.merchant_id,
                    merchantBuyer.merchant_display_name
                );
            }
        });
    }

    onMount(() => {
        activeRows = allRows;
        searchRows = allRows;
    });

    function showInvoiceStatementModal() {
        let companies = [];
        if (selectedCompanyValue && !selectedCompanyValue.startsWith("All")) {
            const [companyName, companyNationalIdNumber, countryCode] =
                selectedCompanyValue.split(":");
            companies = [
                {
                    legal_name: companyName,
                    national_identification_number: companyNationalIdNumber,
                    country_code: countryCode
                }
            ];
        }

        const props = {
            exportUrl: `${BASE_URL}/v1/portal/buyer/invoice/statement`,
            additionalFilters: {
                payment_status: paymentStatus,
                buyer_companies: companies
            },
            dropDownChoices: merchantCompanies,
            isBuyerPortal: true,
            id: "statement-of-account-modal"
        };
        modalState.actions.setModal("", props, StatementOfAccountModal);
    }

    $: {
        switch (selectedTab) {
            case "All":
                activeRows = allRows;
                paymentStatus = null;
                break;
            case "Unpaid and partially paid":
                activeRows = filterUnpaidInvoicesTable(allRows);
                paymentStatus = "NOT_PAID";
                break;
            case "Paid":
                activeRows = filterPaidInvoicesTable(allRows);
                paymentStatus = "PAID";
                break;
        }
        searchRows = activeRows;
        setSearch(searchValue);
    }
</script>

<div class="invoices-container">
    <!-- TODO use PageHeader -->
    <div class="page-header">
        <div class="header-content">
            <div class="header-text">
                <p class="text-3xl font-bold header-text">Invoices</p>
                {#if $buyerData?.billing_account_companies?.size > 0}
                    <p class="sub-header">
                        If you have an account that generates statements, then invoices for that
                        account aren't displayed in the table below.
                    </p>
                {/if}
            </div>
            {#if selectorInput?.options?.size > 2}
                <div class="buyer-name">
                    <Selector
                        options={selectorInput.options}
                        bind:selectedKey={selectedCompanyValue}
                        bind:visibleText={changedValue}
                        placeholder={selectorInput.placeholder}
                    />
                </div>
            {/if}
        </div>
        <div class="tab-headers">
            {#each TABS as tab}
                <button
                    class="cursor-pointer px-6 mr-2 h-10 tab-header"
                    on:click={() => setTab(tab)}
                    class:selected={selectedTab === tab}
                >
                    {tab}
                </button>
            {/each}
        </div>
    </div>
    <div class="rounded-lg bg-white mx-8 mt-6 mb-8 orders-summary">
        <div class="flex items-center justify-between mx-8 pt-4 mb-3">
            <div class="flex max-w-7xl flex-col">
                <p class="text-lg font-bold">{selectedTab} invoices</p>
                <!-- <p class="text-sm explanation-text">{explanationText}</p> -->
            </div>
            <div class="search">
                <div style="width:inherit">
                    <Search
                        bind:searchValue
                        {setSearch}
                        placeholder="Search by invoice number / merchant name / invoice number / payment status"
                    />
                </div>
                {#if activeRows.length > 0}
                    <button
                        class="button-secondary-gray button-sm border"
                        style="width:100%;max-width:fit-content;"
                        on:click={showInvoiceStatementModal}>Download statement of account</button
                    >
                {/if}
            </div>
        </div>
        {#key searchRows}
            <InvoicesTable {selectedTab} {searchRows} />
        {/key}
    </div>
</div>

<style>
    .page-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        border-bottom: 1px solid #e4e7ec;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 32px;
        background-color: #ffffff;
        border-bottom-width: 1px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        align-items: center;
    }

    .invoices-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .orders-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
    }

    .header-text {
        line-height: 42px;
    }
    .tab-headers {
        height: 2.5rem;
        display: flex;
        box-sizing: border-box;
    }

    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }

    .tab-header {
        line-height: 2.5rem;
        font-size: 14px;
        &.selected {
            border-bottom: 3px solid var(--primary-600);
        }
    }

    .search {
        width: 75%;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
        align-items: center;
    }

    .buyer-name {
        font-weight: 700;
        padding-right: 2rem;
    }
    .sub-header {
        color: var(--gray-600);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    .header-text {
        display: flex;
        flex-direction: column;
    }
</style>
