<script lang="ts">
    import { onMount } from "svelte";

    import { getEnvironmentName } from "@/services/environment";

    const INTERVAL_TO_CHECK_WORKING_HOURS = 1; // minutes
    let CHAT_WIDGET_KEY: string = "";

    function isWorkingHours(): boolean {
        const now = new Date();

        // Uncomment the below line for debugging
        // return new Date().getMinutes() === 30;

        // Get the current day, time, and month in GMT
        const day = now.getUTCDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
        const hour = now.getUTCHours();
        const date = now.getUTCDate(); // Day of the month (1-31)
        const month = now.getUTCMonth(); // January = 0, February = 1, ..., December = 11

        // Check if it's Monday to Friday (day 1-5) and between 9:00-16:00
        const isWeekday = day >= 1 && day <= 5;
        const isWorkingTime = hour >= 9 && hour < 16;

        // Exclude Christmas (Dec 25) and New Year's Day (Jan 1)
        const isHoliday = (month === 11 && date === 25) || (month === 0 && date === 1);

        return isWeekday && isWorkingTime && !isHoliday;
    }

    function setWidgetsVisibility() {
        const widget = document.getElementById("launcher");
        if (widget) {
            widget.style.display = isWorkingHours() ? "block" : "none";
        } else {
            console.warn("ChatSupport is not initialized");
        }
    }

    switch (getEnvironmentName()) {
        case "production":
            CHAT_WIDGET_KEY = "7a2fa327-0b3c-46a8-8466-966e99d91bcf";
            break;
        case "sandbox":
            CHAT_WIDGET_KEY = "320a4f4c-2134-4dd5-8437-b02f1e98bdd0";
            break;
        default:
            CHAT_WIDGET_KEY = "b737752d-c3d3-4d72-95b5-61a0ab463ff9";
            break;
    }
    onMount(() => {
        setInterval(setWidgetsVisibility, INTERVAL_TO_CHECK_WORKING_HOURS * 60 * 1000);
    });
</script>

<svelte:head>
    <script
        async
        defer
        id="ze-snippet"
        on:load={() => setTimeout(setWidgetsVisibility, 1000)}
        src="https://static.zdassets.com/ekr/snippet.js?key={CHAT_WIDGET_KEY}"
    ></script>
</svelte:head>
