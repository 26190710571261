<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import Icon from "../../components/icons/Icon.svelte";
    import type { IBanner } from "../../static/types";
    import HelpMenu from "./HelpMenu.svelte";
    import PageHeaderBanner from "./PageHeaderBanner.svelte";

    export let title: string;
    export let description: string = "";
    export let descriptionHtml: string = "";
    export let showAccountAlert = false;
    export let backLink: string = "";
    export let pageBanner: IBanner = null;
    export let showSummaryInfo = false;
    export let hideHelpMenu = false;
    export let helpMenuLinks: Record<string, string> = {};

    // TODO move to buyer/invoices
    // const buyerQuickLinks = {
    //     invoices: {
    //         [$_("helpLinks.buyerInvoiceFaq")]: helpLinks.BUYER_INVOICE_FAQ,
    //         [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
    //     }
    // };
</script>

<header class:header_pb={$$slots.tabs}>
    <div class:main={backLink}>
        {#if backLink}
            <button class="back-link" on:click={() => navigate(backLink)}>
                <Icon name="chevron-left" size={24} fill="var(--ctnGeneralSecondary)" />
            </button>
        {/if}
        <div class="primary">
            <h1 class="title">{title}</h1>
            <slot name="cta" class="cta" />
            {#if !hideHelpMenu}
                <HelpMenu {helpMenuLinks} />
            {/if}
        </div>
        <div class="secondary">
            <div class="secondary text-gray-700 description">
                {#if descriptionHtml}
                    {@html descriptionHtml}
                {:else if description}
                    {description}
                {/if}
            </div>
            <div class:summary-info_mt={$$slots["summary-info"] && showSummaryInfo}>
                <slot name="summary-info" />
            </div>
        </div>
    </div>
    <div class="tabs" class:tabs_mt={$$slots.tabs}>
        <slot name="tabs" class="tabs" />
    </div>
</header>
<PageHeaderBanner {showAccountAlert} {pageBanner} />

<style lang="scss">
    header {
        padding-block: 1.5rem;
        padding-inline: 32px;
        background: white;
        border-bottom: 1px solid #e4e7ec;
        position: sticky;
        top: 0;
        z-index: 109;

        .main {
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: min-content auto;
            column-gap: 8px;
        }

        .primary {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        }

        .secondary {
            grid-column: 2;
        }

        .tabs_mt {
            margin-block-start: 0.75rem;
        }

        .summary-info_mt {
            margin-block-start: 1rem;
        }
    }
    .header_pb {
        padding-bottom: 0;
    }
    .back-link {
        color: #0056b3;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
    }
    h1.title {
        font-size: 1.25rem;
        line-height: 2;
        font-weight: 700;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--ctnGeneralPrimary);
    }
    .description {
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: normal;
    }
</style>
