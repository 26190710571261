<script lang="ts">
    import { _ } from "svelte-i18n";

    import Table from "../../../components/table/Table.svelte";
    import { TAB_ITEMS } from "./tableConstants";
    import { loggedInAsMerchant } from "../../../store/merchant/account.store";
    import {
        actions as insightActions,
        insight as creditInsights,
        alertDetail,
        insightsLoading,
        alertLoading,
        showInsightsSettings
    } from "../../../store/merchant/creditInsights.store";
    import utils from "../../../utilsV2/currency";
    import CustomerInsightsSettings from "./CustomerInsightsSettings.svelte";
    import CreditAlertModal from "./CreditAlertModal.svelte";

    let numDisplayedRecords = 0;
    let tableHeaders = [];
    let tableRows = [];
    let selectedCustomer;
    const pageNum = 1;

    let showAlertModal = false;

    const TABLE_ACTION_ALERT = "alert";
    const TABLE_ACTION_SETTINGS = "settings";

    function titleToTranslation(title) {
        switch (title) {
            case "Customer":
                return $_("riskManagement.creditInsightsTable.customer");
            case "Credit used":
                return $_("riskManagement.creditInsightsTable.credit_used");
            case "Credit available":
                return $_("riskManagement.creditInsightsTable.credit_available");
            case "Credit limit":
                return $_("riskManagement.creditInsightsTable.credit_limit");
            case "Total spend last 30 days":
                return $_("riskManagement.creditInsightsTable.total_spend_last_30_days");
            case "Actions":
                return $_("riskManagement.creditInsightsTable.actions");
            default:
                return title;
        }
    }

    $: if ($loggedInAsMerchant) {
        insightActions.getInsights();
    }

    function getCreditUsedColour(creditLimitUsedPercentage) {
        if (creditLimitUsedPercentage >= 95) {
            return "var(--ctnSemanticErrorPrimary)";
        } else if (creditLimitUsedPercentage >= 80 && creditLimitUsedPercentage < 95) {
            return "var(--ctnSemanticWarningPrimary)";
        } else {
            return "var(--ctnSemanticInfoPrimary)";
        }
    }

    function buildCreditInsightsTable($creditInsights) {
        const tableRows = [];

        for (const insight of $creditInsights) {
            const customerID = insight.buyer_organization_number;
            const rowItem = {
                Customer: {
                    tableItemBold: insight.buyer_company_name,
                    subItem: customerID
                },
                "Credit used": {
                    tableItemBold: `${parseFloat(insight.credit_limit_used_percentage).toFixed(
                        insight.credit_limit_used_percentage % 1 === 0 ? 0 : 1
                    )}%`,
                    itemTextColor: getCreditUsedColour(insight.credit_limit_used_percentage)
                },
                "Credit available": {
                    tableItemBold: utils.formatCurrency(insight.credit_limit_currency)(
                        insight.credit_limit_available
                    )
                },
                "Credit limit": {
                    tableItem: utils.formatCurrency(insight.credit_limit_currency)(
                        insight.credit_limit
                    ),
                    subItem: $_("riskManagement.creditInsightsTable.setBy", {
                        values: {
                            companyName: insight.credit_limit_provider
                        }
                    }),
                    subIconName: insight.credit_limit_provider == "Two" ? "logo" : ""
                },
                "Total spend last 30 days": {
                    tableItem: utils.formatCurrency(insight.credit_limit_currency)(
                        insight.total_order_spend_last_30_days
                    )
                },
                Actions: {
                    icons: [
                        {
                            description: "sendEmailAlert",
                            faIcon: "faBell",
                            id: customerID,
                            action: TABLE_ACTION_ALERT,
                            tooltip: $_("riskManagement.alerts.sendAlert")
                        },
                        {
                            description: "customerSettings",
                            faIcon: "faGear",
                            id: customerID,
                            action: TABLE_ACTION_SETTINGS,
                            tooltip: $_("riskManagement.creditInsightsTable.customerSettings")
                        }
                    ]
                }
            };
            tableRows.push(rowItem);
        }
        return tableRows;
    }

    $: {
        tableRows = buildCreditInsightsTable($creditInsights);
        numDisplayedRecords = tableRows.length;
    }

    function tableIconClicked({ detail: { id, action } }) {
        selectedCustomer = $creditInsights.find(
            ({ buyer_organization_number }) => buyer_organization_number === id
        );
        if (action === TABLE_ACTION_SETTINGS) {
            insightActions.toggleSettings(true);
        } else if (action === TABLE_ACTION_ALERT) {
            showAlertModal = true;
            insightActions.getAlertDetails(selectedCustomer?.buyer_company_id);
        }
    }

    $: {
        tableHeaders = TAB_ITEMS["Credit insights"].tableHeaders;
        tableHeaders = tableHeaders.map((item) => {
            return {
                ...item,
                translation: titleToTranslation(item.title)
            };
        });
    }
</script>

<div class="orders-list-container">
    <Table
        numRows={25}
        numAllRecords={numDisplayedRecords}
        {tableHeaders}
        {tableRows}
        {pageNum}
        useBackendPagination={false}
        loading={$insightsLoading || !$loggedInAsMerchant}
        on:tableIconClicked={tableIconClicked}
    />

    {#if $loggedInAsMerchant && !$insightsLoading && !numDisplayedRecords}
        <div class="w-full h-48 flex flex-col items-center pt-8">
            <img src="/pictures/mailboxstate.svg" alt="mailbox" />
            <p class="my-4 font-semibold text-black-dark text-center">
                {$_("riskManagement.noCustomers")}
            </p>
        </div>
    {/if}

    {#if $showInsightsSettings && selectedCustomer}
        <CustomerInsightsSettings {selectedCustomer} />
    {/if}

    <CreditAlertModal
        bind:showModal={showAlertModal}
        bind:alertDetail={$alertDetail}
        isLoading={$alertLoading}
        {selectedCustomer}
    />
</div>

<style>
    .orders-list-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 80vh;
    }
</style>
