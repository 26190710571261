<script lang="ts">
    import Fa from "svelte-fa";
    import { Circle } from "svelte-loading-spinners";
    import { Tooltip } from "@two-ui/core";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

    import Chart from "../../../../components/chart/MixedChart.svelte";
    import environment from "@/services/environment";

    export let title;
    export let titleTooltip = "";
    export let subtitle = "";
    export let subtitleTooltip = "";
    export let chartData;
    export let isLoading = false;
    export let linkLabel = "";
    export let showCustomLegend = true;
</script>

<div class="mt-4 pb-8 relative w-full">
    <div class="mt-4 ml-6 mb-10">
        <span class="font-bold text-2xl">
            {@html title}
        </span>
        {#if titleTooltip}
            <Tooltip placement="center" position="right" wrapLines width={276} label={titleTooltip}>
                <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
            </Tooltip>
        {/if}
    </div>
    {#if subtitle}
        <div class="mt-2 ml-6 mb-6">
            <span class="font-bold text-lg">
                {subtitle}
            </span>
            <Tooltip placement="center" position="right" wrapLines label={subtitleTooltip}>
                <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
            </Tooltip>
        </div>
    {/if}
    <Chart {showCustomLegend} {...chartData} />

    {#if linkLabel}
        <div class="explore-exposure mx-6 mt-8">
            {linkLabel}
            <Fa size="md" icon={faArrowRight} color="var(--ctnSemanticHighlightPrimary)" />
        </div>
    {/if}

    {#if isLoading}
        <div class="chart_loading absolute top-0 w-full h-full flex items-center">
            <div class="m-auto">
                <Circle color={environment.branding.chartMainColor} />
            </div>
        </div>
    {/if}
</div>

<style>
    .explore-exposure {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        color: var(--ctnSemanticHighlightPrimary);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
</style>
