import { writable } from "svelte/store";

import { NotificationType, type IStatement } from "../../static/types";
import notificationState from "../notifications.store";

import { GET_STATEMENT, GET_STATEMENT_DOWNLOAD } from "../../static/endPoints";
import API from "../../services/apiService";

interface IStatementData {
    statements: IStatement[];
}

function createStatement() {
    const statement = writable([]);
    const loading = writable(false);

    const setStatementLoading = (value: boolean): void => {
        loading.set(value);
    };

    return {
        subscribe: statement.subscribe,
        loading: loading.subscribe,
        actions: {
            getStatement: (params?: string): void => {
                setStatementLoading(true);
                API.get(`${GET_STATEMENT}${params ? `?${params}` : ""}`)
                    .then((data: IStatementData) => {
                        const { statements } = data;
                        const enrichedStatements: IStatement[] = statements.map((statementData) => {
                            return {
                                ...statementData,
                                selected: false,
                                id: String(new Date().getTime())
                            };
                        });
                        statement.set(enrichedStatements);
                        setStatementLoading(false);
                    })
                    .catch(() => {
                        setStatementLoading(false);
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Merchant statement get failed."
                        );
                    });
            },

            getStatementDownload: (params: string) => {
                API.get(`${GET_STATEMENT_DOWNLOAD}${params ? `?${params}` : ""}`).catch(() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        "Merchant statement xlsx file get failed."
                    );
                });
            },

            // Show All statements
            setSelectAll: (value: boolean): void => {
                statement.update((statementsData: IStatement[]): IStatement[] => {
                    const statements: IStatement[] = statementsData.map(
                        (statement: IStatement): IStatement => {
                            statement.selected = value;
                            return statement;
                        }
                    );
                    return statements;
                });
            },
            // Show filtered transactions
            toggleOneSelection: (id: string, selected: boolean): void => {
                statement.update((statements: IStatement[]): IStatement[] =>
                    statements.map((statement: IStatement) => {
                        if (statement.id === id) {
                            statement.selected = selected;
                        }
                        return statement;
                    })
                );
            }
        }
    };
}

const statementState = createStatement();
export default statementState;
