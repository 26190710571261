<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import CreditInsightsTable from "./CreditInsightsTable.svelte";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import Search from "../../../components/search/Search.svelte";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import CreditInsightsFilter from "../../../components/filters/riskManagement/CreditInsightsFilter.svelte";
    import ExposurePage from "./exposure/ExposurePage.svelte";
    import OverviewPage from "./overview/OverviewPage.svelte";
    import { loggedInAsMerchant } from "../../../store/merchant/account.store";
    import { actions as insightActions } from "../../../store/merchant/creditInsights.store";

    export let TABS = [
        $_("riskManagement.overview.title"),
        $_("riskManagement.exposure.title"),
        $_("riskManagement.creditInsights")
    ];
    let searchValue: string = "";
    let filterQueryString: string = "";

    let selectedTab = TABS[0];

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        getInsights();
    }

    const filtersUpdated = (filterQuery: string) => {
        filterQueryString = filterQuery;
        getInsights();
    };

    function getInsights() {
        if ($loggedInAsMerchant) {
            insightActions.getInsights(searchValue, filterQueryString);
        }
    }
</script>

<MetaWrapper title={$_("riskManagement.title")}>
    <div class="page-container">
        <PageHeader title={$_("riskManagement.title")}>
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <button
                        class="tab-header"
                        on:click={() => (selectedTab = tab)}
                        class:selected={selectedTab === tab}
                    >
                        {tab}
                    </button>
                {/each}
            </div>
        </PageHeader>
        <div class="content-container">
            {#if selectedTab === TABS[0]}
                <OverviewPage />
            {:else if selectedTab === TABS[1]}
                <ExposurePage />
            {:else if selectedTab === TABS[2]}
                <div class="rounded-lg bg-white mx-8 mt-6 mb-8">
                    <div class="table-header-bar">
                        <div class="table-title">
                            <p class="text-lg font-bold">
                                {selectedTab}
                            </p>
                            <span class="tooltip-container" style="margin-top: 2px;">
                                <ToolTip
                                    width="200px"
                                    position="right"
                                    content={$_("riskManagement.buyersApproachingLimit")}
                                >
                                    <Fa
                                        size="sm"
                                        icon={faCircleQuestion}
                                        color="var(--primary-500)"
                                    />
                                </ToolTip>
                            </span>
                        </div>
                        <div class="search">
                            <Search
                                bind:searchValue
                                {setSearch}
                                placeholder={$_("riskManagement.searchPlaceholder")}
                            />

                            <CreditInsightsFilter updateTable={filtersUpdated} />
                        </div>
                    </div>
                    <CreditInsightsTable />
                </div>
            {/if}
        </div>
    </div>
</MetaWrapper>

<style lang="scss">
    .tooltip-container {
        margin: auto 0.5rem;
    }
    * {
        box-sizing: border-box;
    }

    .page-container {
        background-color: inherit;
        height: 100%;
    }

    .tab-headers {
        height: 2.5rem;
        display: flex;
    }

    .tab-header {
        padding-inline: 24px;
        line-height: 2.5;
        font-size: 0.9rem;
    }

    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }

    .table-header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: stretch;
        align-content: center;
    }
    .table-title {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        color: var(--ctnGeneralPrimary);
    }
    .table-header-bar div {
        margin: 1.25rem 2rem 1.25rem 2rem;
    }
    .search {
        width: 70%;
        display: flex;
        gap: 0.5rem;
    }
</style>
