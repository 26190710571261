const debounce = (callback: (...args: any[]) => any, wait: number) => {
    let timeoutId = null;
    return (...args_: any[]) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args_);
        }, wait);
    };
};

export function toTitleCase(word: string): string {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export function titleCase(input: string[]): string[] {
    const titleCasedElements: string[] = [];

    for (const element of input ?? []) {
        if (!element) return;
        const inputWordArray = element.split(" ");
        const outputWordArray = [];
        for (const word of inputWordArray) {
            if (word.length > 0) {
                outputWordArray.push(toTitleCase(word));
            }
        }

        if (outputWordArray.length > 0) {
            titleCasedElements.push(outputWordArray.join(" "));
        }
    }
    return titleCasedElements;
}

export default {
    debounce
};

export function formatLocaleDateString(inputDate: string | Date, includeTime = false): string {
    let formattedDate: string;
    try {
        const dateObj = new Date(inputDate);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        formattedDate = `${day}/${month}/${year}`;
        if (includeTime) {
            const hours = dateObj.getHours().toString().padStart(2, "0");
            const minutes = dateObj.getMinutes().toString().padStart(2, "0");
            formattedDate += ` ${hours}:${minutes}`;
        }
    } catch (err) {
        console.log(err);
        // output input to at least have something displayed
        formattedDate = inputDate as string;
    }
    return formattedDate;
}
